exports.components = {
  "component---src-features-blog-blog-post-template-tsx": () => import("./../../../src/features/blog/BlogPostTemplate.tsx" /* webpackChunkName: "component---src-features-blog-blog-post-template-tsx" */),
  "component---src-features-experiments-experiment-template-tsx": () => import("./../../../src/features/experiments/ExperimentTemplate.tsx" /* webpackChunkName: "component---src-features-experiments-experiment-template-tsx" */),
  "component---src-features-work-work-project-template-tsx": () => import("./../../../src/features/work/WorkProjectTemplate.tsx" /* webpackChunkName: "component---src-features-work-work-project-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acknowledgements-tsx": () => import("./../../../src/pages/acknowledgements.tsx" /* webpackChunkName: "component---src-pages-acknowledgements-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-experiments-tsx": () => import("./../../../src/pages/experiments.tsx" /* webpackChunkName: "component---src-pages-experiments-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learning-tsx": () => import("./../../../src/pages/learning.tsx" /* webpackChunkName: "component---src-pages-learning-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

